import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FlexibleContent from "../components/flexibleContent"

export default ({ data, location }) => {
  const {
    databaseId,
    title,
    excerpt,
    flexibleContent,
    seoFields
  } = data.siteFields.page

  return (
    <Layout location={ location }>
      <SEO 
        title={title} 
        description={excerpt} 
        seoFields={seoFields}
      />
      <FlexibleContent 
        id={databaseId} 
        content={flexibleContent} 
        location={location}
      />
    </Layout>
  )
}

export const query = graphql`
    query($databaseId: ID!) {
        siteFields {
            page(id: $databaseId, idType: DATABASE_ID) {
                databaseId
                title
                date
                content(format: RENDERED)
                seoFields {
                  metaTitle
                  metaDescription
                  ogpImage {
                    mediaItemUrl
                  }
                }
                featuredImage {
                    node {
                      altText
                      title(format: RENDERED)
                      mediaItemUrl
                      slug
                    }
                }
                flexibleContent {
                    ... FlexibleContentFragment
                }
            }
        }
    }
`
